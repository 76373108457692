import React from 'react'
import eKTP from 'assets/icons/e_ktp.svg'
import LockClosed from 'assets/icons/lock_closed.svg'

const EKTP = () => (
  <div className="w-full max-w-[480px] block mx-auto px-6 py-8 bg-white mb-2 shadow-[_inset_0px_12px_10px_-10px_rgba(0,0,0,0.08)]">
    <h1 className="text-[#2b2b2b] text-xl font-black mb-4">
      Hai Mama Papa, terima kasih sudah tertarik untuk gabung KasAnak!
    </h1>
    <p className="text-[#2b2b2b]">
      Berikut hal yang <strong>perlu kamu siapkan</strong> untuk proses pendaftaran:
    </p>
    <div className="flex mt-8">
      <div className="w-[54px]">
        <img src={eKTP} alt="e-KTP" className="w-full" />
      </div>
      <div className="pl-4 w-full">
        <strong className="text-[#863DC4]">e-KTP</strong>
        <p className="text-[#2b2b2b] text-sm mt-1">
          Siapkan e-KTP kamu untuk mempercepat pengisian data.&nbsp;
          <span className="text-[#6A6A6A]">
            (Jika yang diasuransikan adalah pasangan, maka siapkan e-KTP pasanganmu)
          </span>
        </p>
      </div>
    </div>
    <div className="flex mt-8 rounded-[8px] bg-[#F8F8F8] p-4">
      <div className="w-[24px]">
        <img src={LockClosed} alt="e-KTP" className="w-full" />
      </div>
      <div className="pl-4 w-full text-sm text-[#6A6A6A]">
        Datamu hanya digunakan untuk verifikasi keanggotaan dan dijamin kerahasiaannya
      </div>
    </div>
  </div>
)

export default EKTP
