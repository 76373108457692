import React, { useEffect, useState, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import cn from 'clsx'
import ReactSlider from 'react-slider'
import Checkbox from 'react-custom-checkbox'
import { FiCheck } from 'react-icons/fi'
import DatePicker from '../common/DatePicker'
import { getCOI, grossUpTotal } from 'utils'

const Form = () => {
  const {
    control,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onChange'
  })

  const [calcTotalTabungan, setTotalTabungan] = useState(0)
  const [calcTotalDonasi, setTotalDonasi] = useState(0)
  const [calcTotalKontribusi, setTotalKontribusi] = useState(0)
  const [calcSantunan, setCalcSantunan] = useState(0)
  const [calcUsia, setUsia] = useState(0)
  const [calcTotalBulanan, setTotalBulanan] = useState(0)
  const [calcTambahDonasi, setTambahDonasi] = useState(0)
  const [, setGrossUp] = useState(0)
  const [, setDonasiGrossUp] = useState(0)
  const [grossupChecked, setGrossupChecked] = useState(true)
  const [hideGrossup, setHideGrossup] = useState(false)
  const [calcTotalRemainder, setTotalRemainder] = useState(0)

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      window.location.replace(
        'https://form.kasanak.com/formulir?total='.concat(
          calcTotalBulanan.toLocaleString('id-ID'),
          '&santunan=',
          calcSantunan.toLocaleString('id-ID'),
          '&tabungan=',
          calcTotalTabungan.toLocaleString('id-ID'),
          '&kontribusi=',
          calcTotalKontribusi.toLocaleString('id-ID'),
          calcTotalDonasi > 0 ? '&donasi=' + calcTotalDonasi.toLocaleString('id-ID') : ''
        )
      )
    },
    [calcTotalBulanan, calcSantunan, calcTotalTabungan, calcTotalKontribusi, calcTotalDonasi]
  )

  useEffect(() => {
    const getTotalBulanan = () => {
      if (calcTotalTabungan === 0 || calcSantunan === 0 || calcUsia === 0) {
        return setTotalBulanan(0)
      }

      let tabunganKenaUjroh = calcTotalTabungan
      let tabunganTidakKenaUjroh = calcTotalTabungan
      if (calcTotalTabungan > 300000) {
        tabunganKenaUjroh = 300000
      }
      tabunganTidakKenaUjroh = calcTotalTabungan - tabunganKenaUjroh
      var tabarru = (calcSantunan * getCOI(calcUsia)) / 1000
      tabarru = Math.ceil(tabarru/500)*500
      const kontribusi = (tabunganKenaUjroh + tabarru) / 0.9 // 0.9 == 1 - 10%
      const totalKontribusi = Math.round(tabunganTidakKenaUjroh + kontribusi)
      setTotalKontribusi(totalKontribusi)
      let tambahDonasi = calcTambahDonasi
      if (calcTambahDonasi < 10000) {
        tambahDonasi = 0
      }

      const totalKontribusiDenganDonasi = totalKontribusi + tambahDonasi
      let grossUp = 0
      let donasiGrossUp = 0

      const remainder = totalKontribusiDenganDonasi % 1000
      setTotalRemainder(remainder)
      setHideGrossup(remainder === 0)

      if (grossupChecked && remainder !== 0) {
        grossUp = grossUpTotal(totalKontribusiDenganDonasi)
        donasiGrossUp = grossUp - totalKontribusiDenganDonasi
        tambahDonasi += donasiGrossUp

        setGrossUp(grossUp)
        setTotalBulanan(grossUp)
        setTotalDonasi(tambahDonasi)
        setDonasiGrossUp(donasiGrossUp)
      } else {
        setTotalBulanan(totalKontribusiDenganDonasi)
        setTotalDonasi(tambahDonasi)
        setGrossUp(totalKontribusiDenganDonasi)
        setDonasiGrossUp(0)
      }
    }

    getTotalBulanan()
  }, [calcTotalTabungan, calcSantunan, calcUsia, calcTambahDonasi, grossupChecked])

  const onChangeDatePicker = useCallback(
    (field) => (e) => {
      const usiaTahun = dayjs().diff(e, 'years', true)
      setUsia(Math.round(usiaTahun))
      field.onChange(e)
    },
    [setUsia]
  )

  const datePickerClassName = useMemo(
    () =>
      cn({
        'calendar-icon appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 font-semibold focus:outline-none text-semibold': true,
        'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]': !errors.tanggalLahir,
        'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
          !errors.tanggalLahir && !!calcUsia,
        'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]': !!errors.tanggalLahir
      }),
    [errors.tanggalLahir, calcUsia]
  )

  const disableSubmit =
    !!errors?.jumlahTabungan ||
    !calcTotalTabungan ||
    !!errors?.santunan ||
    !calcSantunan ||
    !!errors?.tanggalLahir ||
    !calcUsia

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full max-w-[480px] block mx-auto p-6 pt-8 bg-white">
        <h6 className="text-[#2b2b2b] text-lg font-bold mb-4">
          Pertama, tentukan <span className="text-[#863DC4]">nominal nabung rutinmu</span> untuk
          dana pendidikan
        </h6>
        <label htmlFor="jumlahTabungan" className="block text-[#0C0C0C] text-xs mb-2">
          Tabungan Rutin Per Bulan (Minimal Rp100.000)
        </label>
        <Controller
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <NumericFormat
              className={cn({
                'appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 leading-tight font-semibold focus:outline-none text-semibold': true,
                'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]':
                  !errors.jumlahTabungan,
                'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
                  !errors.jumlahTabunga && !!calcTotalTabungan,
                'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]':
                  !!errors.jumlahTabungan
              })}
              displayType="input"
              type="text"
              decimalSeparator=","
              thousandSeparator="."
              placeholder="Rp300.000"
              onValueChange={(v) => {
                setTotalTabungan(v.floatValue)
                onChange({
                  type: 'text',
                  target: { value: v.floatValue, name }
                })
              }}
              name={name}
              value={value}
              onBlur={onBlur}
              ref={ref}
              prefix="Rp"
            />
          )}
          rules={{
            required: { value: true, message: 'Tabungan rutin harus diisi' },
            min: { value: 100000, message: 'Tabungan rutin minimal Rp100.000' }
          }}
          name="jumlahTabungan"
          control={control}
        />
        {errors.jumlahTabungan && (
          <span className="text-[#C44C4C] text-sm">{errors.jumlahTabungan.message}</span>
        )}
        <div className="my-8">
          <hr className="border-dashed border-[#989898] opacity-75" />
        </div>
        <h6 className="text-[#2b2b2b] text-lg font-bold mb-4">
          Lalu, tentukan <span className="text-[#863DC4]">besaran santunan beasiswa</span> yang kamu
          ingin siapkan melalui asuransi jiwa
        </h6>
        <label className="block text-[#0C0C0C] text-xs font-medium mb-2">
          Besaran santunan beasiswa
        </label>
        <Controller
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <>
              <div className="mt-4">
                <ReactSlider
                  className="w-full h-9"
                  max={250000000}
                  min={100000000}
                  marks={[100000000, 150000000, 200000000, 250000000]}
                  defaultValue={100000000}
                  step={5000000}
                  onChange={(val) => {
                    setValue('santunan', val)
                    setCalcSantunan(val)
                    onChange({
                      type: 'text',
                      target: { value: val, name }
                    })
                  }}
                  value={calcSantunan || 100000000}
                  thumbClassName="h-full aspect-square rounded-full bg-[#C598E5] cursor-grab border-8 border-[#7324B6]"
                  renderTrack={(props, state) => {
                    const isLast = state.index !== 0
                    const isFirst = state.index === 0
                    return (
                      <div
                        {...props}
                        className={cn({
                          'h-1/3 top-1/2 -translate-y-1/2 rounded-full': true,
                          'bg-[#E8E8E8]': isFirst ? isLast : isLast,
                          'bg-[#A978D4]': !isFirst ? !isLast : isFirst
                        })}
                      />
                    )
                  }}
                  renderMark={(props) => {
                    switch (props.key) {
                      case 150000000:
                        props.style.left = '31.5%'
                        break
                      case 200000000:
                        props.style.left = '63%'
                        break
                      case 250000000:
                        props.style.left = undefined
                        props.style.right = 0
                        break
                      default:
                    }
                    return (
                      <div
                        {...props}
                        className={cn({
                          'top-1/2 -translate-y-1/2 h-5 w-5 rounded-full cursor-pointer': true,
                          'bg-[#7324B6]': props.key <= (calcSantunan || 0),
                          'bg-[#6A6A6A]': props.key > (calcSantunan || 0)
                        })}
                      />
                    )
                  }}
                />
              </div>
              <div className="relative flex justify-between w-full mt-2">
                <div className="relative">100Jt</div>
                <div className="relative">150Jt</div>
                <div className="relative">200Jt</div>
                <div className="relative">250Jt</div>
              </div>
              <label className="block text-[#0C0C0C] text-xs mb-2 mt-6">
                Atau input berdasarkan keinginan
              </label>
              <NumericFormat
                className={cn({
                  'appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 leading-tight font-semibold focus:outline-none text-semibold': true,
                  'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]':
                    !errors.santunan,
                  'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
                    !errors.santunan && !!calcSantunan,
                  'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]': !!errors.santunan
                })}
                displayType="input"
                type="text"
                decimalSeparator=","
                thousandSeparator="."
                placeholder="Rp100.000.000"
                onValueChange={(v) => {
                  setCalcSantunan(v.floatValue)
                  onChange({
                    type: 'text',
                    target: { value: v.floatValue, name }
                  })
                }}
                name={name}
                value={value}
                onBlur={onBlur}
                ref={ref}
                prefix={'Rp'}
              />
            </>
          )}
          rules={{
            required: { value: true, message: 'Santunan harus diisi' },
            min: { value: 100000000, message: 'Santunan minimal Rp100.000.000' },
            max: { value: 250000000, message: 'Santunan maksimal Rp250.000.000' }
          }}
          defaultValue="100000000"
          name="santunan"
          control={control}
        />
        {errors.santunan && (
          <span className="text-[#C44C4C] text-sm">{errors.santunan.message}</span>
        )}
        <div className="my-8">
          <hr className="border-dashed border-[#989898] opacity-50" />
        </div>
        <div className="relative w-full mb-3">
          <h6 className="text-[#2b2b2b] text-lg font-bold mb-4">
            Masukkan tanggal lahir <span className="text-[#863DC4]">pihak yang diasuransikan</span>{' '}
            (kamu/pasangan)
          </h6>
          <label className="block text-[#0C0C0C] text-xs mb-2">
            Tanggal lahir pihak yang diasuransikan
          </label>
          <Controller
            control={control}
            name="tanggalLahir"
            rules={{
              required: { value: true, message: 'Tanggal lahir harus diisi' }
            }}
            valueAsDate="true"
            render={({ field }) => (
              <DatePicker
                onChange={onChangeDatePicker(field)}
                selected={field.value}
                className={datePickerClassName}
              />
            )}
          />
          {errors.tanggalLahir && (
            <span className="text-[#C44C4C] text-sm">{errors.tanggalLahir.message}</span>
          )}
        </div>
      </div>
      <div className="w-full max-w-[480px] mx-auto bg-white">
        <div className="w-full p-6 bg-[#F2F3F4] rounded-t-[8px]">
          <h6 className="text-[#2b2b2b] text-lg font-bold mb-4">
            (Opsional) Tambah donasi rutin untuk program{' '}
            <span className="text-[#863DC4]">Kas Anak Asuh</span>
          </h6>
          <label
            htmlFor="tambahDonasi"
            className={cn({
              'block text-[#0C0C0C] text-xs mb-2 mt-4': true
            })}
          >
            Program beasiswa & binaan untuk anak-anak dhuafa, dikelola oleh Yayasan Kitabisa
          </label>
          <Controller
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                className={cn({
                  'appearance-none border border-[#C8C8C8] rounded-[8px] w-full py-4 px-3 leading-tight font-semibold focus:outline-none text-semibold': true,
                  'text-gray focus:border-[#863DC4] focus:bg-[rgba(134,61,196,0.04)]':
                    !errors.tambahDonasi,
                  'text-gray !border-[#863DC4] bg-[rgba(134,61,196,0.04)]':
                    !errors.tambahDonasi && !!calcTambahDonasi,
                  'text-[#C44C4C] !border-[#C44C4C] bg-[rgba(196,76,76,0.04)]':
                    !!errors.tambahDonasi
                })}
                displayType="input"
                type="text"
                decimalSeparator=","
                thousandSeparator="."
                placeholder="Rp10.000"
                defaultValue="0"
                onValueChange={(v) => {
                  var val = v.floatValue
                  if (val === undefined || v === '') {
                    val = 0
                  }
                  setTambahDonasi(val)
                  onChange({
                    type: 'text',
                    target: { value: val, name }
                  })
                }}
                name={name}
                value={value}
                onBlur={onBlur}
                ref={ref}
                prefix="Rp"
              />
            )}
            rules={{
              validate: (v) => {
                return v === 0 || v >= 10000 || 'Donasi minimal Rp10.000'
              }
            }}
            name="tambahDonasi"
            control={control}
          />
          {errors.tambahDonasi && (
            <span className="text-[#C44C4C] text-sm">{errors.tambahDonasi.message}</span>
          )}

          <h6 className="text-[#2b2b2b] text-lg font-bold my-4">
            Berikut <span className="text-[#863DC4]">nominal yang kamu bayarkan</span> untuk
            tabungan + asuransi jiwa setiap bulan
          </h6>
          <Controller
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumericFormat
                readOnly={true}
                className={cn({
                  'appearance-none border rounded-[8px] w-full py-4 px-3 leading-tight font-semibold focus:outline-none text-semibold': true,
                  'text-[#9ca3af]': disableSubmit || !calcTotalBulanan,
                  'text-[#863DC4]': !disableSubmit && !!calcTotalBulanan
                })}
                decimalSeparator=","
                thousandSeparator="."
                prefix="Rp"
                suffix=" / bulan"
                value={disableSubmit ? 0 : calcTotalBulanan}
              />
            )}
            name="totalBulanan"
            control={control}
          />
          {!hideGrossup && (
            <Checkbox
              borderColor="#863DC4"
              icon={<FiCheck color="#863DC4" />}
              className={cn({
                'text-[#2b2b2b] mt-4': true
              })}
              labelClassName={cn({
                'text-[#2b2b2b] text-xs font-bold mt-4': true
              })}
              label="Bulatkan nominal untuk donasi ke Kas Anak Yatim"
              checked={grossupChecked}
              onChange={(value, event) => {
                setGrossupChecked(value)
              }}
            />
          )}
          <label htmlFor="totalBulanan" className="block text-[#0C0C0C] text-xs mt-2">
            *Sudah Termasuk Biaya Administrasi Anggota Untuk Kas Anak{' '}
            {grossupChecked && calcTotalRemainder !== 0 ? 'dan Donasi ke Kas Anak Yatim' : ''}
          </label>
        </div>
        <div className="text-center p-6 bg-[#863DC4]">
          <div className="pb-6">
            <hr className="border-dashed border-white" />
          </div>
          <button
            className={cn({
              'w-full text-[#873dc4] text-lg font-bold px-20 py-3 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150': true,
              'bg-[#C598E5]': disableSubmit,
              'bg-white': !disableSubmit
            })}
            type="submit"
            disabled={disableSubmit}
          >
            Lanjut
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
