import React from 'react'
import Header from 'components/common/Header'

const Layout = ({ children }) => {
  return (
    <section className="w-full bg-[#f7f7f7]">
      <Header />
      {children}
    </section>
  )
}

export default Layout
