import React from 'react'
import EKTP from 'components/Home/EKTP'
import Form from 'components/Home/Form'

const Home = () => (
  <>
    <EKTP />
    <Form />
  </>
)

export default Home
