import { Routes, Route, Navigate } from 'react-router-dom'
import Home from 'containers/Home'
import Layout from 'containers/Layout'
// import Pendidikan from 'containers/Pendidikan'

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        {/*<Route path="/pendidikan" element={<Pendidikan />} />*/}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  )
}

export default App
