import { navigateToHome } from 'utils'
import React from 'react'

const Header = () => (
  <div className="w-full max-w-[480px] block mx-auto p-6 bg-white rounded-b-[8px]">
    <img
      src="https://imagedelivery.net/V1rHMrkE5rLIFJ8jdBoCpA/177ed539-f96e-4c63-d8d2-ba6fe9d7f000/public"
      className="w-[160px] h-auto"
      alt="logo-kasanak"
      onClick={navigateToHome}
    />
  </div>
)

export default Header
